import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Config from '../../../helpers/Config';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import BestFacilities from '../../components/shared/BestFacilities';
import about1 from '../../resources/themeContent/images/theme-images/about1.jpg';
import about2 from '../../resources/themeContent/images/theme-images/about2.jpg';
import signature from '../../resources/themeContent/images/theme-images/signature.png';


const About = () => {
  const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);

  return (
    <>


      <Helmet>
        <title>{siteTitle} - About Us</title>
        <meta name="description" content={siteTitle + " - About us page"}  />
        <meta name="keywords" content="About us"></meta>
      </Helmet>

      <SiteBreadcrumb title="About Us" />

      <section className="about-area ptb-60">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="about-content">
                <h2>**About Born2Fly Clothing Ltd.**</h2>
                <p>Welcome to Born2Fly Clothing Ltd., where fashion meets freedom, and style takes flight. </p>

                <p>At Born2Fly, we believe that clothing is more than just fabric and threads; it's a reflection of your individuality, your aspirations, and your dreams. Our brand was born out of the idea that clothing should empower you to embrace your uniqueness and inspire you to soar to new heights.</p>

                <p>Our Commitment:
- **Quality Craftsmanship:** We take pride in the meticulous craftsmanship of each and every garment we create. Our team of skilled artisans ensures that every stitch, seam, and detail is executed to perfection, delivering clothing that not only looks amazing but also stands the test of time.

- **Innovative Designs:** Our design philosophy is rooted in creativity and innovation. We constantly push the boundaries of fashion to bring you cutting-edge styles that set trends and express your personality effortlessly.

- **Sustainability:** We are committed to a greener, more sustainable future. Born2Fly Clothing Ltd. is dedicated to using eco-friendly materials, reducing waste, and promoting ethical production practices. We believe in dressing well while doing good for the planet.

- **Inclusivity:** Fashion should be for everyone. We offer a diverse range of sizes and styles to cater to all body types and embrace the beauty of diversity. Born2Fly is proud to be a brand that celebrates inclusivity and promotes body positivity.

- **Customer-Centric Approach:** Your satisfaction is our priority. We strive to provide you with exceptional customer service and a seamless shopping experience. Your feedback fuels our growth and inspires us to continue creating clothing that speaks to your soul.

Born2Fly Clothing Ltd. is more than just a clothing brand; it's a lifestyle that encourages you to spread your wings, chase your dreams, and express yourself through the art of fashion. We invite you to explore our collections, discover your unique style, and embark on a journey of self-expression and empowerment.

Join us in embracing the freedom to fly, one stylish outfit at a time. Born2Fly Clothing Ltd. - Where Style Takes Flight.</p>

                <div className="signature mb-0">
                  <img src={signature} alt="image" />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="about-image">
                <img src={about1} className="about-img1" alt="image" />
                <img src={about2} className="about-img2" alt="image" />
              </div>
            </div>
          </div>
        </div>
      </section>



      <BestFacilities />

    </>
  );

}

export default About;
